<template>
    <el-main>
        <el-button style="margin-bottom:16px" type="primary" size="small"
            @click="$router.push('/extension/gatewayWholesale/add')">添加商品</el-button>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="商品名称：">
                <el-input v-model="formData.title" size="small" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button size="small" @click="getDataList(1)" type="primary">搜索</el-button>
                <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="formData.list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="title" label="商品信息" align="center">
                <template v-slot="{ row }">
                    <div class="goods_info">
                        <el-image :src="row.goods_image" style="width:40px;height:40px"
                            :preview-src-list="[row.goods_image]"></el-image>
                        <div class="goods_name">{{ row.goods_title }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="价格" align="center"></el-table-column>
            <el-table-column label="第一阶起批量" align="center">
                <template v-slot="{ row }">
                    {{ row.ladder_price_info[0].start }} ~ {{ row.ladder_price_info[0].end }}
                </template>
            </el-table-column>
            <el-table-column prop="first_price" label="批发价格" align="center"></el-table-column>
            <el-table-column label="第二阶起批量" align="center">
                <template v-slot="{ row }">
                    {{ row.ladder_price_info[1].start }} ~ {{ row.ladder_price_info[1].end }}
                </template>
            </el-table-column>
            <el-table-column prop="second_price" label="批发价格" align="center"></el-table-column>
            <el-table-column label="第三阶起批量" align="center">
                <template v-slot="{ row }">
                    {{ row.ladder_price_info[2].start }}及以上
                </template>
            </el-table-column>
            <el-table-column prop="third_price" label="批发价格" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="editData(row)">编辑</el-button>
                    <el-button type="text" @click="deleteData(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="formData.total" :page="formData.page" :pageNum="formData.rows" @updatePageNum="updateData">
        </Paging>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            formData: {
                page: 1,
                rows: 10,
                total: 0,
                list: [],
                title: ''
            }
        }
    },
    created () {
        this.getDataList()
    },
    methods: {
        searchClear () {
            this.formData.title = ''
            this.getDataList(1)
        },
        updateData (val, status) {
            if (status == 0) {
                this.formData.rows = val;
            } else {
                this.formData.page = val;
            }
            this.getDataList();
        },
        getDataList (style) {
            if (style) this.formData.page = 1
            let obj = {
                page: this.formData.page,
                rows: this.formData.rows,
            }
            if (this.formData.title) obj.title = this.formData.title
            this.$axios.post(this.$api.push.wholesale.listWholesale, obj).then(res => {
                if (res.code == 0) {
                    res.result.list.map(item => {
                        let first_price = []
                        let second_price = []
                        let third_price = []
                        for (let i = 0; i < item.ladder_price_info.length; i++) {
                            if (i === 0 || i % 3 == 0) {
                                first_price.push(parseFloat(item.ladder_price_info[i].price))
                                continue
                            }
                            if (i === 1 || (i - 1) % 3 == 0) {
                                second_price.push(parseFloat(item.ladder_price_info[i].price))
                                continue
                            }
                            if (i === 2 || (i - 2) % 3 == 0) {
                                third_price.push(parseFloat(item.ladder_price_info[i].price))
                                continue
                            }
                        }
                        item.first_price = Math.max(...first_price)
                        item.second_price = Math.max(...second_price)
                        item.third_price = Math.max(...third_price)
                    })
                    this.formData.list = res.result.list
                    this.formData.total = res.result.total
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        editData (data) {
            this.$router.push(`/extension/gatewayWholesale/edit?edit_id=${data.id}`)
        },
        deleteData (data) {
            this.$confirm('此操作将移除该批发商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                return this.$axios.post(this.$api.push.wholesale.delWholesale, {
                    id: data.id
                })
            }).then(res => {
                if (res.result) {
                    this.$message.success('删除成功')
                    this.getDataList()
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(() => { });
        }
    },
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;

    .goods_info {
        display: flex;
        align-items: center;

        .el-image {
            margin-right: 10px;
            flex-shrink: 0;
        }

        .goods_name {
            text-align: left;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
}
</style>